<template>
  <div style="border: 1px dashed lightgrey">
    <!-- <div ref="pdflabels" style="transform: scale(2); transform-origin: top left"> -->
    <!-- <div  class="tl-pdflabels d-flex flex-row flex-wrap"> -->
    <div ref="pdflabels" class="tl-pdflabels d-flex flex-row flex-wrap">
      <div
        v-for="(climb, i) in climbsToPrint"
        :key="climb.id"
        class="tl-pdflabel d-flex flex-column align-center"
        :class="{
          'tl-pdflabel--border-right': i % 2 == 0,
          'tl-pdflabel--border-bottom': i < 2,
        }"
      >
        <!-- <img :src="gymLogoData" max-height="100" class="tl-pdflabel__gym-logo" /> -->
        <div class="tl-pdflabel__climb-circle">
          <div v-if="climb.number != null" class="tl-pdflabel__number">{{ climb.number }}</div>
          <div v-if="climb.rope_number != null" class="tl-pdflabel__rope">Rope {{ climb.rope_number }}</div>
          <tl-grade-circle
            :grade="climb.grade"
            :color="climb.hold.color"
            :color-secondary="climb.hold.color_secondary"
            outlined
            size="280"
            style="font-size: 150px !important; min-height: 280px"
          />
        </div>

        <div class="d-flex flex-column justify-space-around text-center mt-4" style="height: 120px">
          <div class="tl-pdflabel__name">{{ climb.name || '-' }}</div>
          <div class="tl-pdflabel__remarks" v-if="climb.remarks">
            {{ climb.remarks }}
          </div>
        </div>

        <v-spacer />

        <v-row no-gutters class="tl-pdflabel__meta flex-nowrap">
          <v-col cols="auto">
            <span v-if="climb.date_set" style="white-space: nowrap">{{ moment(climb.date_set).format('l') }}</span>
          </v-col>
          <v-col cols="auto" class="text-right">
            <span v-if="climb.setter">{{ climb.setter.name }}</span>
          </v-col>
        </v-row>

        <div style="width: 100%">
          <v-divider class="my-6" />
          <v-row no-gutters>
            <v-col class="text-uppercase">
              <div class="body-1 font-weight-light grey--text mb-2">Tick your climbs in the app</div>
              <div style="font-size: 3rem; line-height: 2.2rem">
                <span class="primary--text font-weight-bold">Top</span>
                <span class="grey--text font-weight-thin" style="margin-left: 2px">Logger</span>
              </div>
            </v-col>
            <v-col cols="auto" class="d-flex justify-end align-end">
              <logo-new style="height: 68px" />
            </v-col>
          </v-row>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { jsPDF } from 'jspdf'
import tlGradeCircle from '../tl-grade-circle'
import domtoimage from 'dom-to-image'
import logoNew from './logo-new'

export default {
  components: {
    tlGradeCircle,
    logoNew,
  },
  props: {
    climbs: { type: Array, default: () => [] },
  },
  data: () => ({
    climbsToPrint: [],
    // gymLogoData: null,
  }),
  mounted() {
    this.makePdf()
    // this.fetchGymLogo()
  },
  methods: {
    // async fetchGymLogo() {
    //   this.gymLogoData = await this.toDataURL(this.$store.state.gym.srcIcon)
    //   this.makePdf()
    // },
    // toDataURL(url) {
    //   return fetch(url)
    //     .then(response => response.blob())
    //     .then(
    //       blob =>
    //         new Promise((resolve, reject) => {
    //           const reader = new FileReader()
    //           reader.onloadend = () => resolve(reader.result)
    //           reader.onerror = reject
    //           reader.readAsDataURL(blob)
    //         })
    //     )
    // },
    async makePdf() {
      const pdf = new jsPDF({ unit: 'px', format: 'a4', hotfixes: ['px_scaling'] })
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()

      // Four climbs per A4 page:
      let pages = Math.ceil(this.climbs.length / 4)
      for (var iPage = 0; iPage < pages; iPage++) {
        // Update the template:
        this.climbsToPrint = [...this.climbs].slice(iPage * 4, (iPage + 1) * 4)
        await this.$nextTick()
        await new Promise(resolve => setTimeout(resolve, 100)) // Possible render-bug fix. Not sure if it helps...
        // Take a picture:
        let imgData = await domtoimage.toPng(this.$refs.pdflabels)
        // let imgData = await domtoimage.toPng(this.$refs.pdflabels, { height: 2828, width: 2000 })
        // Add it to a page on pdf:
        if (iPage != 0) pdf.addPage()
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
      }
      pdf.save(`${this.moment().format('YYYY-MM-DD')}-route-labels.pdf`)
      this.$store.dispatch('dialog/close')
    },
  },
}
</script>

<style lang="sass">
.tl-pdflabels
  width: 1000px
  height: 1414px
.tl-pdflabel
  width: 500px
  height: 50%
  padding: 24px
  padding-top: 100px
  position: relative
  &--border-right
    border-right: 1px dashed lightgrey
    margin-right: -1px
  &--border-bottom
    border-bottom: 1px dashed lightgrey
  // &__gym-logo
  //   border-radius: 50%
  //   position: absolute
  //   left: 20px
  //   top: 20px
  &__climb-circle
    position: relative
  &__number,
  &__rope
    position: absolute
    left: 50%
    padding: 0 6px
    transform: translateX(-50%)
    height: 82px
    border-radius: 12px
    background-color: var(--v-grey-darken4)
    color: var(--v-grey-lighten4)
    text-align: center
    font-size: 24px
    line-height: 30px
    white-space: nowrap
  &__number
    top: -32px
    padding-bottom: 50px
  &__rope
    bottom: -32px
    padding-top: 50px
  &__name
    display: inline-block
    font-size: 2.2rem
    line-height: 2.8rem
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    overflow: hidden
    text-overflow: ellipsis
  &__remarks
    display: inline-block
    max-width: 450px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    font-size: 1.25rem
    font-weight: 900
  &__meta
    width: 100%
    align-items: flex-end
    justify-content: space-between
    font-size: 1.5rem
    line-height: 1.5rem
    font-weight: 300
</style>
